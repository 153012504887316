<template>
  <div id="main">
    <v-index>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>招生</el-breadcrumb-item>
        <el-breadcrumb-item><a style="color: #000000;">师生交流</a></el-breadcrumb-item>
      </el-breadcrumb>

      <v-weburl-iframe url="student/chat" />
    </v-index>
  </div>
</template>

<script>
import VIndex from "@/components/common";
import VWeburlIframe from "@/components/weburl-iframe";
export default {
  name: 'examine',
  components: {VWeburlIframe, VIndex}
}
</script>

<style scoped>

</style>
